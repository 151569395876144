<template>
  <div class="activity_info">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <essential-information
            v-if="id"
            :id="id"
            :activeNameTab="activeName"
            :activityType="activityType * 1"
          ></essential-information>
          <essential-information
            v-else
            :activeNameTab="activeName"
            :activityType="activityType * 1"
          ></essential-information>
        </el-tab-pane>
        <el-tab-pane label="活动回顾" name="second" v-if="activityType * 1">
          <activity-review
            :activeNameTab="activeName"
            :activeId="id"
            :activityType="activityType * 1"
          ></activity-review>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import essentialInformation from "./essentialInformation.vue";
import activityReview from "./activityReview.vue";
export default {
  name: "activityInfo",
  components: {
    essentialInformation,
    activityReview,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {
    // 0新增 1编辑
    activityType() {
      return this.$route.query.activityType;
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {},
  methods: {
    //tabs页切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.activity_info {
  width: 100%;

  /deep/ .el-card {
    height: 100% !important;
    overflow: auto;
  }
  /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/.el-tabs__item.is-active {
    color: #8483d0;
  }

  /deep/.el-tabs__active-bar {
    background-color: #8483d0;
  }

  /deep/ .el-tabs__item:hover {
    color: #8483d0;
    cursor: pointer;
  }
}
</style>