<template>
  <div>
    <el-upload
      action
      list-type="picture-card"
      :limit="5"
      accept="image/*"
      :http-request="upload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :on-exceed="handleExceed"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { uploadAdmin } from '@/api/common'
export default {
  props: {
    fileList: {
      type: Array,
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      testList: []
    }
  },
  methods: {
    handleExceed(files, fileList) {
      this.$messageError("最多只能上传五张图片");
    },
    handleRemove(file, fileList) {
      this.$emit('removeImg', file)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(file) {
      // this.fileList = []

      const form = new FormData()

      form.append('files', file.file)

      const {data: res} = await uploadAdmin(form)
      if (res.code === 10200) {
        this.testList = this.fileList
        this.testList.push({
          name: file.file.name,
          uid: file.file.uid,
          url: res.data.fileLook[0],
        })
        this.$emit('updateImg', this.testList)
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
