<template>
  <div class="essential_information">
    <el-form
      :model="queryInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="活动名称" prop="activeName">
        <el-input
          v-model="queryInfo.activeName"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动类型" prop="activityType">
        <el-radio-group v-model="queryInfo.activityType">
          <el-radio :label="1">赛事活动</el-radio>
          <el-radio :label="2">科普活动</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动banner" prop="image">
        <el-upload
          class="avatar-uploader"
          action=""
          :http-request="upload"
          :show-file-list="false"
          accept="image/*"
        >
          <img v-if="queryInfo.image" :src="queryInfo.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="addinfo_title">(图片比例：3:2)</div>
      </el-form-item>
      <el-form-item label="活动时间" prop="startTime">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="timeChange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动形式" prop="activityForm">
        <el-radio v-model="queryInfo.activityForm" :label="0">线上</el-radio>
        <el-radio v-model="queryInfo.activityForm" :label="1">线下</el-radio>
        <el-radio v-model="queryInfo.activityForm" :label="2"
          >线上+线下</el-radio
        >
      </el-form-item>
      <el-form-item label="活动地址" v-if="queryInfo.activityForm">
        <el-input v-model="queryInfo.address" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="活动详情海报" prop="files">
        <up-load-image
          @updateImg="updateImg"
          :fileList.sync="queryInfo.files"
          @removeImg="removeImg"
        ></up-load-image>
      </el-form-item>
      <el-form-item class="fixed_bottom">
        <el-button type="primary" @click="addActivity">保存</el-button>
        <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { uploadAdmin } from "@/api/common.js";
import { addActivity, findById, editActivity } from "@/api/activity.js";
import UpLoadImage from "../../../components/UpLoadImage.vue";
export default {
  components: { UpLoadImage },
  props: {
    activeNameTab: {
      type: String,
    },
    activityType: {
      type: Number,
    },
    id: {
      type: String,
    },
  },
  name: "essentialInformation",
  data() {
    return {
      //表单数据
      queryInfo: {
        activeName: "", //活动名称
        address: "", //活动地址
        endTime: "", //结束时间
        files: [], //活动详情海报
        image: "", //banner图
        startTime: "", //开始时间
        activityType: "", //活动类型
        activityForm: "", //活动形式
      },
      time: [], //时间
      //验证规则
      rules: {
        activityForm: [
          { required: true, message: "请选择活动形式", trigger: "change" },
        ],
        activityType: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        activeName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        image: [{ required: true, message: "请上传活动banner" }],
        startTime: [{ required: true, message: "请选择活动时间" }],
        files: [{ required: true, message: "请上传活动banner" }],
      },
    };
  },
  watch: {
    activeNameTab: {
      handler: function (newVal) {
        if (newVal === "first") {
          if (this.activityType) {
            this.seach();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //获取单个活动数据
    async seach() {
      const { data: res } = await findById({ id: this.id });
      if (res.data) {
        this.queryInfo = Object.assign(res.data);
        this.time = [this.queryInfo.startTime, this.queryInfo.endTime];
        this.queryInfo.files = this.queryInfo.activeFileViews.map((item) => {
          return {
            name: item.file,
            url: item.file,
          };
        });
      }
    },
    //点击保存
    addActivity() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res;
          if (this.activityType) {
            this.queryInfo.files = this.queryInfo.files.map((item) => {
              return item.url;
            });
            res = await editActivity(this.queryInfo);
          } else {
            this.queryInfo.files = this.queryInfo.files.map((item) => {
              return item.url;
            });
            res = await addActivity(this.queryInfo);
          }
          if (res.data.success) {
            this.$message.success(
              `活动${this.activityType ? "编辑" : "创建"}成功`
            );
            this.$router.go(-1);
          } else {
            this.$message.error(
              `活动${this.activityType ? "编辑" : "创建"}失败`
            );
          }
        }
      });
    },
    //移除图片
    removeImg(val) {
      this.queryInfo.files = this.queryInfo.files.filter((item) => {
        return item !== val;
      });
    },
    //上传图片返回的数组
    updateImg(val) {
      console.log(val);
      this.queryInfo.files = val;
    },
    //选择时间
    timeChange(val) {
      this.queryInfo.endTime = this.time ? val[1] : "";
      this.queryInfo.startTime = this.time ? val[0] : "";
    },
    //上传图片
    async upload(params) {
      const form = new FormData();

      form.append("files", params.file);

      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.queryInfo.image = res.data.fileLook[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.essential_information {
  width: 100%;
  overflow: auto;
  height: 620px;
  position: relative;

  .timePicker {
    display: flex;
  }

  /deep/ .el-upload--picture-card {
    background-color: #fff !important;
  }

  .demo-ruleForm {
    width: 60%;
    margin: 0 auto;

    .fixed_bottom {
      position: absolute;
      left: 20%;

      .el-button--primary {
        width: 136px;
        height: 40px;
        background-color: #8483d0;
        border: 1px solid #8483d0;
      }

      .new_btn {
        color: #8483d0;
      }

      .el-button {
        width: 136px;
        height: 40px;
        border: 1px solid #8483d0;
      }
    }

    /deep/ .el-upload--picture-card {
      width: 88px;
      height: 88px;
      line-height: 96px;
    }

    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 88px;
      height: 88px;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      border: 1px dashed #eee;
      width: 88px;
      height: 88px;
      line-height: 88px;
      text-align: center;
    }
    .avatar {
      width: 88px;
      height: 88px;
      display: block;
    }

    .addinfo_title {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>