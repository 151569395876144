<template>
  <div class="activity_review">
    <!-- <up-load-video /> -->
    <div class="activity_review_title">
      <i class="el-icon-warning"></i
      >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB，富文本里面为图片和文字。)
    </div>
    <!-- <ueditor :value="queryInfo.reviewActivities" :config="config" ref="ue" /> -->
    <editorbar v-model="queryInfo.reviewActivities" :isClear="isClear" />
    <div class="fixed_bottom">
      <el-button type="primary" @click="reviewClick">发布</el-button>
      <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import ueditor from "../../../components/ue/ueditor.vue";
import {
  activeReview,
  AddActiveReview,
  editActiveReview,
} from "@/api/activity.js";
import UpLoadVideo from "../../../components/UpLoadVideo.vue";
import Editorbar from "../../../components/Editorbar.vue";
export default {
  components: { ueditor, UpLoadVideo, Editorbar },
  name: "activityReview",
  props: {
    activeId: {
      type: String,
    },
    activeNameTab: {
      type: String,
    },
  },
  data() {
    return {
      queryInfo: {
        activeId: "",
        reviewActivities: "",
      },
      isClear: false,
      isAdd: true,
      config: {
        initialFrameHeight: 400,
        initialFrameWidth: 1200,
        toolbars: [
          [
            "fontsize",
            "|",
            "paragraph",
            "forecolor",
            "bold",
            "italic",
            "blockquote",
            "insertunorderedlist",
            "insertorderedlist",
            "justifycenter",
            "link",
            "unlink",
            "source",
            "undo",
            "redo",
            "insertimage",
          ],
        ],
        serverUrl: "https://vecto.elitego.cn/api", // 请求配置文件的接口
      },
    };
  },
  watch: {
    activeNameTab: {
      handler: function (newVal) {
        if (newVal === "second") {
          this.seach();
        }
      },
      immediate: true,
    },
  },
  methods: {
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    // 获取富文本框内容
    getUEContents() {
      this.queryInfo.reviewActivities = this.$refs.ue.getUEContent();
    },
    //发布回顾
    async reviewClick() {
      // this.getUEContents();
      if (this.isAdd) {
        this.queryInfo.activeId = this.activeId * 1;
        const { data: res } = await AddActiveReview(this.queryInfo);
        if (res.success) {
          this.$message.success("新增成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("新增失败，请稍候重试");
        }
      } else {
        const { data: res } = await editActiveReview(this.queryInfo);
        if (res.success) {
          this.$message.success("编辑成功！");
          this.$router.go(-1);
        } else {
          this.$message.error("编辑失败，请稍候重试");
        }
      }
    },
    //获取单个数据
    async seach() {
      const { data: res } = await activeReview({ activeId: this.activeId });
      if (res.data) {
        this.isAdd = false; //编辑
        this.queryInfo = res.data;
        setTimeout(() => {
          this.$refs.ue.setUEContent(res.data.reviewActivities);
        }, 100);
      } else {
        this.isAdd = true; //新增
      }
    },
  },
};
</script>

<style lang="less" scoped>
.activity_review {
  width: 100%;

  .activity_review_title {
    margin: 10px 0;
    overflow: hidden;
    color: #faa871;
    font-size: 14px;

    i {
      color: #faa871;
      margin-right: 8px;
    }
  }

  .fixed_bottom {
    margin: 20px auto;

    .el-button--primary {
      width: 136px;
      height: 40px;
      background-color: #8483d0;
      border: 1px solid #8483d0;
    }

    .new_btn {
      color: #8483d0;
    }

    .el-button {
      width: 136px;
      height: 40px;
      border: 1px solid #8483d0;
    }
  }
}
</style>